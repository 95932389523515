import React, {useMemo, useState} from 'react'
import {RedocStandalone} from 'redoc'
import YAML from 'yaml'

const OPEN_API_FILE = 'openapi.yaml';

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [openApiSpec, setOpenApiSpec] = useState();

    useMemo(() => {
        fetch(OPEN_API_FILE)
            .then(file => file.text())
            .then(text => {
                setOpenApiSpec(YAML.parse(text));
                setIsLoading(false);
            });
    }, [])

    if (isLoading) {
        return <>Loading...</>
    }

    return (
        <RedocStandalone
            spec={openApiSpec}
            options={{
                showExtensions: true,
                theme: {
                    colors: {
                        primary: {
                            main: '#59BFAF'
                        },
                        secondary: {
                            main: '#05252D'
                        }
                    }
                }
            }}

        />
    );
};

export default App;
